import './index.scss';
import './responsive.scss';
import Router from './router';
import React, {useState, useCallback, useEffect} from 'react';
// import GlobalStateProvider './service/global-state';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { request, methods } from "./service/http-common";
 
// import { useNavigate } from "react-router-dom";

const GlobalStateProvider = React.lazy(() => import('./service/global-state'))


function App() {

  return (
    <GlobalStateProvider>
      <div className="App">
        <Router />
        <ToastContainer autoClose={3000}/>
      </div>
    </GlobalStateProvider>
  );
}

export default App;
